@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, Roboto, PT Sans, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.button {
    @apply rounded-full px-5 py-3.5 focus:outline-none w-full bg-primary-base text-white hover:bg-primary-hover
}

.input-app-design {
    @apply w-full px-3 text-xs py-3 border border-slate-400 outline-none focus:outline-none rounded-md appearance-none bg-white
}

.rounded-icon-button {
    @apply mb-5 text-2xl rounded-full w-7 h-7 p-1 font-bold border border-slate-700 block cursor-pointer
}

.react-date-picker__wrapper{
    border: none !important;
}

.float-input {
    position: relative;
}

.float-input .input-field {
    transition: 0.5s;
}

.float-input span.span-label {
    left: 0;
    pointer-events: none;
    transition: 0.6s;
}

.float-input .input-field:not(:placeholder-shown) ~ span.span-label,
.float-input .input-field:focus ~ span.span-label {
    transform: translateX(10px) translateY(-7px);
    font-size: 0.65rem;
    font-weight: bold;
    padding: 0 10px;
    background-color: #ffffff;
}

